import React, { useRef } from 'react'
import {
  ButtonBack,
  ButtonNext,
  CarouselProvider,
  Image,
  Slide,
  Slider,
} from 'pure-react-carousel'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'pure-react-carousel/dist/react-carousel.es.css'
import './PhotoCarousel.scss'
import CameraSvg from '../../shared/camera_icon.svg'
import TrashSvg from '../../shared/deleteTrash_icon.svg'

const PhotoCarousel = ({
  tree,
  documents,
  updateDocuments,
  handleAddPhotoFiles,
  showAddDelete = false,
}) => {
  const fileInputRef = useRef(null) // Allows file picker to be reset

  const handleInputClick = () => {
    // Without this reset, it's impossible to re-add a previously added file
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  return (
    <div className="PhotoCarousel">
      <CarouselProvider
        className="carousel-provider"
        naturalSlideWidth={400}
        naturalSlideHeight={100}
        totalSlides={documents.length}
        isIntrinsicHeight={true}
      >
        {documents.length > 0 && (
          <div className="carousel-column left">
            <ButtonBack className="slide-control" id="back-button">
              &lt;
            </ButtonBack>
          </div>
        )}
        <Slider>
          {documents.map(([key, photo], photoIndex) => {
            const src = photo.file_url || URL.createObjectURL(photo.file)
            return (
              <Slide
                index={photoIndex}
                key={key}
                classNameVisible="visible-slide"
                data-photo-key={key}
              >
                <div className="photo">
                  <Image
                    className="carousel-image"
                    src={src}
                    alt={`${tree.name} ${photoIndex}`}
                    hasMasterSpinner={false}
                  />
                </div>
              </Slide>
            )
          })}
        </Slider>
        {documents.length > 0 && (
          <div className="carousel-column right">
            <ButtonNext className="slide-control" id="next-button">
              &gt;
            </ButtonNext>
          </div>
        )}
      </CarouselProvider>
      {showAddDelete && (
        <>
          <label className="add-or-count-photo" htmlFor="choose-photos">
            {documents.length === 0 && <CameraSvg />}
            <span className="slide-control">Add photos</span>
          </label>
          <input
            className="visually-hidden"
            ref={fileInputRef}
            id="choose-photos"
            type="file"
            name="image"
            accept="image/*"
            multiple={true}
            onChange={(fileInputEvent) => handleAddPhotoFiles(fileInputEvent)}
            onClick={handleInputClick}
          />
        </>
      )}
      {showAddDelete && documents.length > 0 && (
        <button
          className="delete"
          type="button"
          aria-label="Delete"
          onClick={() => {
            // find the Slide element with class visible-slide
            const visibleSlide = document.querySelector('.visible-slide')
            // find the data-photo-key attribute from that visible Slide
            const photoKey = visibleSlide.getAttribute('data-photo-key')
            updateDocuments({ [photoKey]: { _destroy: true } })
          }}
        >
          <TrashSvg width="16px" height="16px" fill="white" />
        </button>
      )}
      {/* no add/delete button, then let's show a count of photos */}
      {!showAddDelete && (
        <div className="add-or-count-photo slide-control">
          Photos ({documents.length})
        </div>
      )}
    </div>
  )
}

export default PhotoCarousel
