import React, { useEffect, useState, useReducer } from 'react'
import { isMobile } from '../../shared/media_queries.js'
import ProgressBar from '../application/ProgressBar.jsx'
import RecordObservationsWizardBottomNavbar from './RecordObservationsWizardBottomNavbar.jsx'
import { CLOSE_X_ICON } from '../../shared/constants'
import './RecordObservationsWizard.scss'
import ObservationSelectTrees from './ObservationSelectTrees'
import { ObservationCommonFields } from './ObservationCommonFields'
import { UNIT_OF_ADDITIONAL_OBSERVERS } from './ObservationObservers'
import ObservationTree from './ObservationTree'
import ObservationErrorModal from './ObservationErrorModal.jsx'
import {
  buildProgressSteps,
  createObservationSession,
  today,
  filterAndReshapeDocuments,
} from './observation_utils'
import {
  DEBUG_SHOW_SELECTED_TREES,
  SESSION_STATUS_COMMITTED,
} from './observation_config.jsx'
import ObservationReview from './ObservationReview'
import { deepMerge } from '../../shared/utils.js'

/*
 * For incoming props, there are three patterns:
 * (1) No props, which means we are starting a new observation session with no preselected location or trees
 *     Implies the user selected "Record Observations" from the dashboard.
 * (2) With preselected_location and preselected_tree_ids, set by ObservationsController#wizard
 *     Implies the user selected "Record Observations" from the dashboard for a specific planting or parcel.
 *     The controller expects one of parcel_id, planting_id from this.
 * (3) With observation_session, which will restore a saved session.
 *     Implies the user selected to resume a session from the dashboard "My Observations".
 */
const RecordObservationsWizard = ({
  preselected_location: preselectedLocation,
  preselected_tree_ids: preselectedTreeIds,
  current_user_contact: currentUserContact,
  observation_session: observationSession,
  trait_configs: traitConfigs,
}) => {
  const [currentObservationSession, updateCurrentObservationSession] =
    useReducer(
      deepMerge,
      JSON.parse(JSON.stringify(observationSession)) || { documents: {} }
    )
  const setSessionId = (id) => updateCurrentObservationSession({ id })

  const [sessionState, setSessionState] = useState(null)
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [selectedTrees, setSelectedTrees] = useState([])
  const [commonFields, setCommonFields] = useState({})
  const [uiState, setUiState] = useState({})
  const [currentStep, setCurrentStep] = useState(-1) // let useEffect set to 1
  const [treeIndex, setTreeIndex] = useState(0)
  const [progressSteps, setProgressSteps] = useState(
    buildProgressSteps(-1, selectedTrees.length, currentStep, selectedTrees)
  )
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [errorData, setErrorData] = useState([])
  const [finishInProgress, setFinishInProgress] = useState(false)

  // default observations for any selected tree, defaults are changed in step 2 (common fields)
  const DEFAULT_COMMON_FIELDS = {
    // snake case because these get baked into the selected trees which end up server-side
    observer: { ...currentUserContact },
    additional_observers: UNIT_OF_ADDITIONAL_OBSERVERS,
    recorded_on: today(),
    traits: {},
  }

  // default state of UI page details (handy for usability tweaks)
  const DEFAULT_UI_STATE = {
    isCommonDate: true,
    isCommonObserver: true,
  }

  // handle incoming wizard props, which can be (1) a saved session or (2) a preselected location and tree ids
  useEffect(() => {
    setCommonFields(DEFAULT_COMMON_FIELDS)
    setUiState(DEFAULT_UI_STATE)
    if (observationSession !== null) {
      // we have a saved session, so we restore the state
      const wizardState = observationSession?.wizard_json
      setSessionId(observationSession.id)
      setSessionState(observationSession.status)
      setSelectedLocation(wizardState.selected_location)
      setSelectedTrees(wizardState.selected_trees)
      setCommonFields(wizardState.common_fields)
      setUiState(wizardState.ui_state)
      setTreeIndex(wizardState.tree_index)
      const steps = buildProgressSteps(
        wizardState.tree_index,
        wizardState.selected_trees.length,
        wizardState.current_step,
        wizardState.selected_trees
      )
      setProgressSteps(steps)
      setCurrentStep(wizardState.current_step) // should be last
    } else if (preselectedLocation !== null) {
      // we have a selected location and tree ids (via observations_controller#wizard)
      // build an the array of tree objects from the preselectedLocation that match the preselectedTreeIds
      const treesInit = preselectedLocation.located_trees.filter((tree) =>
        preselectedTreeIds.includes(tree.id)
      )
      // and initialize those trees with the default observation fields
      setSelectedTrees(
        treesInit.map((tree) =>
          deepMerge(tree, { observations: DEFAULT_COMMON_FIELDS })
        )
      )
      setSelectedLocation(preselectedLocation)
      setCurrentStep(1) // should be last
    } else {
      setCurrentStep(1) // should be last in all paths for re-rendering
    }
  }, [])

  // the one place that step 1 (select location and trees) updates the array of selected trees
  // also adds in defaults to newly selected trees
  const updateSelectedTrees = (trees) => {
    const treesNextWithDefaults = [...trees].map((tree) =>
      'observations' in tree
        ? tree
        : deepMerge(tree, { observations: commonFields })
    )
    setSelectedTrees(treesNextWithDefaults)
    const steps = buildProgressSteps(
      treeIndex,
      trees.length,
      currentStep,
      selectedTrees
    )
    setProgressSteps(steps)
  }

  const resetSelectedTrees = () => {
    setSelectedTrees([])
    const steps = buildProgressSteps(treeIndex, 0, currentStep, selectedTrees)
    setProgressSteps(steps)
  }

  // sets which wizard step we are on, and which tree we are looking at in step 3 (tree details)
  // also updates the state of the left side navigation bar
  // used by next/previous navigation and "edit tree" in step 4 (summary)
  const setWizardStep = (step, treeIndexNext) => {
    setCurrentStep(step)
    setTreeIndex(treeIndexNext)
    const steps = buildProgressSteps(
      treeIndexNext,
      selectedTrees.length,
      step,
      selectedTrees
    )
    setProgressSteps(steps)
  }

  const handleOnNext = () => {
    if (currentStep === 1) {
      // we go to step 2 iff there are multiple trees selected, else we go to step 3
      setWizardStep(selectedTrees.length > 1 ? 2 : 3, treeIndex)
      return
    }
    if (currentStep === 2) {
      setWizardStep(3, treeIndex)
      return
    }
    if (currentStep === 3) {
      if (treeIndex < selectedTrees.length - 1) {
        setWizardStep(3, treeIndex + 1)
      } else {
        setWizardStep(4, treeIndex)
      }
      return
    }
    console.debug(
      `on step ${currentStep}, step ${currentStep + 1} is not implemented`
    )
  }

  const handleOnPrevious = () => {
    if (currentStep === 3) {
      if (treeIndex > 0) {
        setWizardStep(3, treeIndex - 1)
      } else {
        // we go to step 2 iff there are multiple trees selected, else we go to step 1
        setWizardStep(selectedTrees.length > 1 ? 2 : 1, treeIndex)
      }
      return
    }
    setWizardStep(currentStep - 1, treeIndex)
  }

  // save the observation session, if this is a new session sessionId will be null
  // this handles both "save & exit" (for an incomplete wizard session) and "finish" (we're done, commit the data)
  const handleOnSave = (isFinished) => {
    const status = isFinished ? 'committed' : 'incomplete_wizard'
    setFinishInProgress(true)

    createObservationSession(
      status,
      selectedLocation,
      selectedTrees,
      commonFields,
      uiState,
      currentStep,
      treeIndex,
      currentObservationSession
    )
      .then(() => {
        window.location.href = '/'
      })
      .catch((exception) => {
        setFinishInProgress(false)
        const errorObj = JSON.parse(exception?.message || '{}') // trigger the unknown error path
        const errorList = errorObj?.data?.errors || ['Unknown error']
        const sessionId = errorObj?.data?.id || null
        setSessionId(sessionId)
        setErrorData(errorList)
        setModalIsOpen(true)
      })
  }

  /* the one place where trees are updated from step 3
   * curried functions let us pass an update fn for a specific tree id to step 3 (tree details)
   * incoming data is deepMerge'd with the existing tree data
   * any trait with a value of null is removed completely
   *   this allows for the user to change their mind about recording a trait (for example the survival trait)
   */
  const updateSelectedTree = (id) => (attributeObj) => {
    const selectedTreesNext = [...selectedTrees]
    const index = selectedTreesNext.findIndex((t) => t.id === id)
    selectedTreesNext[index] = deepMerge(selectedTrees[index], attributeObj)

    selectedTreesNext[index].observations.traits = Object.fromEntries(
      Object.entries(selectedTreesNext[index].observations.traits).filter(
        ([_, value]) => value.value !== null
      )
    )

    setSelectedTrees(selectedTreesNext)
  }

  // the one place where commonFields are updated from step 2
  // changes here propagate into every selected tree's observations field
  //   ergo step 3 (tree details) values may be lost when going BACK to change common fields... and that's ok
  const updateCommonFields = (attributeObj) => {
    setCommonFields((prevState) => deepMerge(prevState, attributeObj))
    const selectedTreesNext = [...selectedTrees]
    setSelectedTrees(
      selectedTreesNext.map((tree) =>
        deepMerge(tree, { observations: attributeObj })
      )
    )
  }

  const updateUiState = (attributeObj) => {
    setUiState((prevState) => deepMerge(prevState, attributeObj))
  }

  // used by step 4 (summary) to go back and edit a specific tree details (in step 3)
  const editTree = (index) => {
    setWizardStep(3, index)
  }

  return (
    <div id="RecordObservationsWizard">
      <div className="progress-bar-container">
        <ProgressBar title="Record an observation" steps={progressSteps} />
        {isMobile() && (
          <a href="/" className="x-button">
            {CLOSE_X_ICON}
          </a>
        )}
      </div>
      <div className="main-container">
        <div className="main">
          {currentStep === 1 && (
            <ObservationSelectTrees
              selectedTrees={selectedTrees}
              setSelectedTrees={updateSelectedTrees}
              resetSelectedTrees={resetSelectedTrees}
              selectedLocation={selectedLocation}
              setSelectedLocation={setSelectedLocation}
            />
          )}
          {currentStep === 2 && (
            <ObservationCommonFields
              selectedTrees={selectedTrees}
              currentUserContact={currentUserContact}
              commonFields={commonFields}
              updateCommonFields={updateCommonFields}
              uiState={uiState}
              updateUiState={updateUiState}
            />
          )}
          {currentStep === 3 && (
            <ObservationTree
              currentUserContact={currentUserContact}
              tree={selectedTrees[treeIndex]}
              updateSelectedTree={updateSelectedTree(
                selectedTrees[treeIndex].id
              )}
              treeIndex={treeIndex}
              isSameDate={uiState.isCommonDate}
              isSameObserver={uiState.isCommonObserver}
              selectedLocation={selectedLocation}
              key={selectedTrees[treeIndex].id}
              documents={filterAndReshapeDocuments(
                currentObservationSession.documents,
                selectedTrees[treeIndex].id
              )}
              updateDocuments={(documents) =>
                updateCurrentObservationSession({ documents })
              }
              traitConfigs={traitConfigs}
            />
          )}
          {currentStep === 4 && (
            <ObservationReview
              trees={selectedTrees}
              documents={currentObservationSession.documents}
              editTree={editTree}
              isCommitted={sessionState === SESSION_STATUS_COMMITTED}
              traitConfigs={traitConfigs}
            />
          )}
        </div>
        <RecordObservationsWizardBottomNavbar
          nextIsDisabled={selectedTrees.length === 0}
          previousIsDisabled={currentStep === 1}
          saveIsDisabled={
            selectedLocation === null || selectedTrees.length === 0
          }
          onNext={handleOnNext}
          onPrevious={handleOnPrevious}
          onSave={() => handleOnSave(false)}
          onFinish={() => handleOnSave(true)}
          onLastStep={currentStep === 4}
          isCommitted={sessionState === SESSION_STATUS_COMMITTED}
          finishInProgress={finishInProgress}
        />
        {DEBUG_SHOW_SELECTED_TREES && (
          <pre className="debug">
            <i>selectedTrees:</i>
            <br />
            {JSON.stringify(selectedTrees, null, 2)}
            <br />
            <i>currentObservationSession.documents:</i>
            <br />
            {Object.values(currentObservationSession.documents).map(
              (document) => JSON.stringify(document, null, 2)
            )}
            <br />
            <i>commonFields:</i>
            <br /> {JSON.stringify(commonFields, null, 2)}
            <br />
            <i>uiState:</i>
            <br /> {JSON.stringify(uiState, null, 2)}
            <br />
          </pre>
        )}
      </div>
      <ObservationErrorModal
        isOpen={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        errors={errorData}
      >
        <p>Some sort of child component</p>
      </ObservationErrorModal>
    </div>
  )
}
export default RecordObservationsWizard
