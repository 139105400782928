import React, { useState } from 'react'
import './TreePhotoCarousel.scss'

const TreePhotoCarousel = ({ tree, photos, start }) => {
  const [photoNumber, setPhotoNumber] = useState(Number(start))
  const activePhoto = photos[photoNumber]
  const numPhotos = photos.length
  const DEBUG = false

  return (
    <div className="TreePhotoCarousel">
      <div className="title">
        <span>
          {photoNumber + 1} / {numPhotos}
        </span>
      </div>
      <button
        className="navigation close-nav"
        type="button"
        onClick={() => {
          window.location = `/trees/${tree.id}`
        }}
      >
        <span>&times;</span>
      </button>
      <button
        className="navigation left-nav"
        type="button"
        onClick={() => {
          const photoNumberNext = (photoNumber - 1 + numPhotos) % numPhotos
          setPhotoNumber(photoNumberNext)
        }}
      >
        <span>&lt;</span>
      </button>
      <button
        className="navigation right-nav"
        type="button"
        onClick={() => {
          const photoNumberNext = (photoNumber + 1) % numPhotos
          setPhotoNumber(photoNumberNext)
        }}
      >
        <span>&gt;</span>
      </button>

      <figure className="single-photo">
        <a
          href={activePhoto.file.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={activePhoto.file.url} alt={`number ${photoNumber}`} />
        </a>
        <figcaption>
          {activePhoto.observer && `by ${activePhoto.observer.full_name}`} on{' '}
          {new Date(activePhoto.created_at).toLocaleDateString()}
        </figcaption>
      </figure>

      {DEBUG && (
        <div className="debug">
          {photos.map((photo, index) => {
            return (
              <figure className="medium-photo" key={photo.id}>
                <a
                  href={photo.file.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={photo.file.url} alt={`number ${index + 1}`} />
                </a>
                <figcaption>
                  {index + 1}. {photo.name} by {photo.observer.full_name} on{' '}
                  {new Date(photo.created_at).toLocaleDateString()}{' '}
                  {photoNumber === index && '\u2714'}
                </figcaption>
              </figure>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default TreePhotoCarousel
