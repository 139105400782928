import ReactRailsUJS from 'react_ujs'

import AddPlantingWizard from './components/planting/AddPlantingWizard.jsx'
import AdvancedTreesSearchObservations from './components/trees/AdvancedTreesSearchObservations.jsx'
import ChapterParcelRansackUncontrolled from './components/application/ChapterParcelRansackUncontrolled.jsx'
import ChapterTreeRansackUncontrolled from './components/application/ChapterTreeRansackUncontrolled.jsx'
import ContactRansack from './components/application/ContactRansack.jsx'
import CrossParentChooser from './components/crosses/CrossParentChooser.jsx'
import EditPlottedPlanting from './components/planting/plotted/EditPlottedPlanting.jsx'
import MobileMenu from './components/application/MobileMenu.jsx'
import MyPlantingsWidget from './components/planting/MyPlantingsWidget.jsx'
import NewPlantingWidget from './components/planting/NewPlantingWidget.jsx'
import ProfileMenu from './components/application/ProfileMenu.jsx'
import RecordObservationsWizard from './components/dashboard/RecordObservationsWizard.jsx'
import RoleAndEntityRansack from './components/application/RoleAndEntityRansack.jsx'
import SavedObservationsTable from './components/dashboard/SavedObservationsTable.jsx'
import SelectAsyncPaginatedRansack from './components/application/SelectAsyncPaginatedRansack.jsx'
import TreePhotoCarousel from './components/trees/TreePhotoCarousel'

const components = {
  AddPlantingWizard,
  AdvancedTreesSearchObservations,
  ChapterParcelRansackUncontrolled,
  ChapterTreeRansackUncontrolled,
  ContactRansack,
  CrossParentChooser,
  EditPlottedPlanting,
  MobileMenu,
  MyPlantingsWidget,
  NewPlantingWidget,
  ProfileMenu,
  RecordObservationsWizard,
  RoleAndEntityRansack,
  SavedObservationsTable,
  SelectAsyncPaginatedRansack,
  TreePhotoCarousel,
}

ReactRailsUJS.getConstructor = (name) => components[name]

ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount, false)
ReactRailsUJS.handleEvent('turbo:frame-load', ReactRailsUJS.handleMount, false)
ReactRailsUJS.handleEvent(
  'turbo:before-render',
  ReactRailsUJS.handleUnmount,
  false
)
