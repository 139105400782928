import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { Tooltip } from 'react-tooltip'
import DatePicker from 'react-datepicker'
import Map from './Map'
import { MANY_TREES } from '../../shared/constants'
import CalendarSvg from '../../shared/elements-icons-fa-solid-calendar-alt.svg'
import TooltipSvg from '../../shared/tooltip.svg'
import 'react-datepicker/dist/react-datepicker.css'

import './DefinePlantingDetails.scss'

const NUM_OPTIONS = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: 'more', label: MANY_TREES },
]

const nameIsInvalid = (locationName) => {
  const regex = /[a-z0-9]+/i
  const matches = locationName.match(regex)
  return matches === null
}

const DefinePlantingDetails = ({
  existingLocationEntities,
  fieldFocus,
  handleEditLocation,
  handleInvalidDetails,
  handleNewDetails,
  isNewLocation,
  location,
  prevLocationName,
  prevLocationType,
  prevNumTrees,
  prevPlantingDate,
}) => {
  const [locationName, setLocationName] = useState(prevLocationName)
  const [locationType, setLocationType] = useState(prevLocationType)
  const [plantingDate, setPlantingDate] = useState(
    prevPlantingDate || new Date()
  )
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [nameError, setNameError] = useState(null)
  const [numTrees, setNumTrees] = useState(prevNumTrees)

  const handleBigNumTrees = () => setNumTrees(Number.MAX_SAFE_INTEGER)

  useEffect(() => {
    if (
      locationName &&
      locationType &&
      plantingDate &&
      numTrees &&
      !nameIsInvalid(locationName)
    ) {
      handleNewDetails(locationName, locationType, plantingDate, numTrees)
    }
  }, [locationName, plantingDate, numTrees])

  useEffect(() => {
    if (numTrees === 'more') {
      // setIsModalOpen(true)
      handleBigNumTrees()
    }
  }, [numTrees])

  useEffect(() => {
    if (locationName && nameIsInvalid(locationName)) {
      setNameError('Planting location name must contain letters or numbers.')
      handleInvalidDetails()
    } else {
      setNameError(null)
    }
  }, [locationName])

  function handlePlantingDate(raw_date) {
    const aDate = new Date(raw_date)
    if (aDate > new Date()) {
      console.warn('Date too big!')
    }
  }

  const locationNameFocus = fieldFocus === 'locationName'
  const plantingDateFocus = fieldFocus === 'plantingDate'
  const numTreesDefaultLabel =
    prevNumTrees === Number.MAX_SAFE_INTEGER ? MANY_TREES : `${prevNumTrees}`
  const numTreesDefaultValue = prevNumTrees
    ? { label: `${numTreesDefaultLabel}`, value: prevNumTrees }
    : null

  return (
    <div id="DefinePlantingDetails">
      <Tooltip
        anchorSelect=".private-tooltip-anchor"
        style={{ zIndex: 999, maxWidth: '400px' }}
      >
        <p>
          Private locations are sites that are not publicly accessible. Their
          name and and approximate location will be viewable by all users, but
          their exact location and other details can only be seen by site
          administrators.
        </p>
      </Tooltip>

      <h2>Enter some details about this planting.</h2>
      <div className="columns">
        <div className="column left">
          <div className="address">
            <span>{location.address}</span>
            {isNewLocation && (
              <button
                onClick={handleEditLocation}
                type="button"
                className="edit-address"
              >
                Edit
              </button>
            )}
          </div>
          <Map
            existingLocationEntities={existingLocationEntities}
            miniMap={true}
            plantingLocation={location.pos}
            showLocationInputBox={false}
          />
        </div>
        <div className="column right">
          <label htmlFor="location-input-input" className="top-header">
            Planting location name
          </label>
          <div className="location-input">
            <input
              id="location-input-input"
              readOnly={!isNewLocation}
              autoFocus={locationNameFocus}
              value={locationName}
              onChange={(e) => setLocationName(e.target.value)}
              type="text"
              placeholder="e.g., My Location"
            />
            {nameError ? (
              <p className="error">{nameError}</p>
            ) : (
              <div className="placeholder" />
            )}
          </div>
          {!location.type && (
            <div className="location-type">
              <h3>Location type</h3>
              <div className="text-and-tooltip">
                <input
                  disabled={!isNewLocation}
                  onClick={() => setLocationType('private')}
                  type="radio"
                  name="radio-group"
                  id="private"
                  value="private"
                  defaultChecked={prevLocationType !== 'public'}
                />
                <label htmlFor="private">Private</label>
                <button type="button" className="private-tooltip-anchor">
                  <TooltipSvg />
                </button>
              </div>
              <p className="radio-description">
                Details about this location will only be visible to site
                administrators.
              </p>
              <input
                disabled={!isNewLocation}
                onClick={() => setLocationType('public')}
                type="radio"
                name="radio-group"
                id="public"
                value="public"
                defaultChecked={prevLocationType === 'public'}
              />
              <label htmlFor="public">Public</label>
              <p className="radio-description">
                Details about this location will be visible to all Dentatabase
                users.
              </p>
            </div>
          )}
          <div id="date-picker" className="date">
            <label>
              <h3>Date of planting (yyyy-mm-dd)</h3>
              <div className="date-and-icon">
                <DatePicker
                  dateFormat="yyyy-MM-dd"
                  // onChange={date => handlePlantingDate(date)}
                  onChange={(date) => setPlantingDate(date)}
                  autoFocus={plantingDateFocus}
                  maxDate={new Date()}
                  selected={plantingDate}
                  placeholderText="Click to select a date"
                  onChangeRaw={(event) =>
                    handlePlantingDate(event.target.value)
                  }
                />
                <CalendarSvg />
              </div>
            </label>
          </div>
          <label className="date-label" htmlFor="numPlanted">
            Total number planted
          </label>
          <Select
            inputId="numPlanted"
            data-testid="select-num"
            className="selector"
            options={NUM_OPTIONS}
            defaultValue={numTreesDefaultValue}
            onChange={(e) => setNumTrees(e.value)}
          />
        </div>
      </div>
      {/*
      <TooManyTreesModal
        isOpen={isModalOpen}
        handleContinue={handleBigNumTrees}
      />
      */}
    </div>
  )
}

export default DefinePlantingDetails
