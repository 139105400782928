import React from 'react'
import { isMobile } from '../../shared/media_queries.js'
import './RecordObservationsWizardBottomNavbar.scss'

const RecordObservationsWizardBottomNavbar = ({
  nextIsDisabled,
  previousIsDisabled,
  saveIsDisabled,
  onNext,
  onPrevious,
  onFinish,
  onLastStep,
  onSave,
  isCommitted,
  finishInProgress,
}) => {
  return (
    <div id="RecordObservationsWizardBottomNavbar">
      {/* for now there is no nav is we look at the committed review page */}
      {!isCommitted && (
        <>
          <div>
            <button
              type="button"
              className="btn btn-primary previous"
              onClick={() => onPrevious()}
              disabled={previousIsDisabled}
            >
              Previous
            </button>
          </div>
          <div>
            {!isMobile() && (
              <a className="cancel" href="/">
                Cancel
              </a>
            )}
            <button
              type="button"
              className="btn btn-secondary save-and-exit"
              onClick={onSave}
              disabled={saveIsDisabled}
            >
              Save & exit
            </button>
            <button
              type="button"
              className={`btn btn-primary ${onLastStep ? 'finish' : 'next'}`}
              onClick={() => (onLastStep ? onFinish() : onNext())}
              disabled={finishInProgress || (!onLastStep && nextIsDisabled)}
            >
              {onLastStep ? 'Finish' : 'Next'}
            </button>
          </div>
        </>
      )}
    </div>
  )
}
export default RecordObservationsWizardBottomNavbar
