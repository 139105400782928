import React from 'react'
import { Tooltip } from 'react-tooltip'
import { ObservationMap } from './ObservationMap'
import { TreeSpaceTooltip } from './TreeSpaceTooltip'

export const TreeTooltips = ({ trees, isMobile }) => {
  if (!trees || trees.length === 0) return null
  return (
    <>
      <Tooltip
        anchorSelect=".tooltip-anchor-observation-map"
        className="tooltip-observation-map-extra-class"
        key={`tooltip-map-${Math.random()}`}
        place={!isMobile ? 'left' : undefined} // undefined ok for mobile, will skip the prop entirely
        render={({ content }) => {
          const tree = trees.find((t) => t.id === Number(content))
          const treeLocation = {
            lat: parseFloat(tree?.latitude),
            lng: parseFloat(tree?.longitude),
            id: tree?.id,
          }
          return (
            <ObservationMap
              center={treeLocation}
              trees={[treeLocation]}
              selectedTreeIds={new Set([treeLocation.id])}
            />
          )
        }}
      />
      <Tooltip
        anchorSelect=".tooltip-anchor-observation-space"
        className="tooltip-observation-space-extra-class"
        key={`tooltip-space-${Math.random()}`}
        variant="light"
        clickable={true}
        render={({ content }) => {
          const tree = trees.find((t) => t.id === Number(content))
          return <TreeSpaceTooltip tree={tree} />
        }}
      />
    </>
  )
}
