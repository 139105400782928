import React from 'react'
import { AsyncPaginate } from 'react-select-async-paginate'

const AsyncPaginatedLocationSelect = ({ defaultValue, id, onChange }) => {
  const itemsPerPage = 10

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const params = {
      limit: itemsPerPage,
      offset: (page - 1) * itemsPerPage,
      name_cont: searchQuery,
    }

    const responseJSON = await fetch(
      `/observations/wizard_get_locations?${$.param(params)}`
    ).then((response) => {
      return response.json()
    })

    return {
      options: responseJSON,
      hasMore: responseJSON.length === itemsPerPage,
      additional: {
        page: page + 1,
      },
    }
  }

  return (
    <div className="AsyncPaginatedLocationSelect">
      <AsyncPaginate
        id={id}
        cacheOptions
        defaultValue={defaultValue}
        getOptionValue={(e) => e.id}
        getOptionLabel={(e) => e.name}
        loadOptions={loadOptions}
        additional={{ page: 1 }}
        onChange={onChange}
        // Hardcoding font-size because iOS has issue/feature where it
        // automatically zooms for smaller font sizes. Future us
        // may wish to pass in a styles object instead.
        styles={{
          valueContainer: (baseStyles) => ({
            ...baseStyles,
            fontSize: '16px',
          }),
        }}
      />
    </div>
  )
}

export default AsyncPaginatedLocationSelect
